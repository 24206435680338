$primary: #0abbb5;
$border-width: 2px;

@import '../node_modules/bootstrap-scss/functions';
@import '../node_modules/bootstrap-scss/variables';
@import '../node_modules/bootstrap-scss/mixins';

$theme-colors: map-remove($theme-colors, "secondary", "success", "info", "warning", "danger", "light", "dark");

@import "../node_modules/bootstrap-scss/bootstrap-reboot";
@import "../node_modules/bootstrap-scss/bootstrap-grid";
@import "../node_modules/bootstrap-scss/nav";
@import "../node_modules/bootstrap-scss/buttons";
@import "../node_modules/bootstrap-scss/modal";
@import "../node_modules/bootstrap-scss/close";
@import "../node_modules/bootstrap-scss/utilities/display";
@import "../node_modules/bootstrap-scss/utilities/screenreaders";


.btn-outline-primary {
  color: darken($primary, 8%);
}

.app {
  .row-logo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-fixed - 4;
    margin: 0;

    .logo {
      width: auto;
      height: 96px;
      @include media-breakpoint-up(md) {
        height: 128px;
      }
      @media all and (max-width: 340px) {
        width: 100%;
        height: auto;
      }
      margin: 1rem 0;
    }
  }

  .main-tabs {
    position: relative;
    z-index: $zindex-fixed - 2;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 128px;
    @include media-breakpoint-up(md) {
      margin-top: 160px;
    }
    &.top {
      .nav-tabs {
        position: fixed;
        z-index: $zindex-fixed;
        top: 0;
        left: 0;
        width: 100%;
      }
      .tab-content {
        padding-top: 46px;
      }
    }
    .nav-tabs {
      display: flex;
      justify-content: space-between;
      border: 0;
      .nav-item {
        width: 50%;
        border-radius: 0;
        text-transform: uppercase;
        text-align: center;
        border: 3px solid $primary;
        font-weight: bold;
        background-color: $white;
        &:first-child {
          border-radius: $border-radius 0 0 0;
        }
        &:last-child {
          border-radius: 0 $border-radius 0 0;

        }
        &.active {
          color: $white;
          background: $primary;
        }
      }
    }
    .tab-content {
      margin-top: 2px;
    }

    .tab-pane {
      position: relative;
      z-index: $zindex-fixed - 1;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-left: 3px solid $primary;
      border-right: 3px solid $primary;
      border-bottom: 3px solid $primary;
      border-radius: 0 0 $border-radius $border-radius;
      //background-color: rgba($primary, .1);
      background-color: rgb(230, 248, 247);

      .content.container {
        .btn-map {
          width: 100%;
          @include media-breakpoint-up(sm) {
            width: auto;
          }
        }
        .net-contacts {
          display: flex;
          justify-content: center;
          a {
            font-size: 1.5rem;
            margin-right: .5rem;
            width: 2rem;
            height: 2rem;
            background-color: $gray-600;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius;
            color: $white;
            &:last-child {
              margin-right: 0;
            }
            &.instagram {
              background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
            }
            &.youtube {
              background-color: #FF0000;
            }
            &.facebook {
              background-color: #4267B2;
            }
            &.vk {
              background-color: #2787F5;
            }
          }
        }
        ul {
          padding: 0;
          list-style: none;
          margin-top: 1rem;
          @include media-breakpoint-only(md) {
            columns: 2;
          }
          @include media-breakpoint-up(lg) {
            columns: 3;
          }

          li:before {
            content: "•";
            color: $primary;
            font-weight: bold;
            display: inline-block;
            width: 1rem;
          }
        }
      }
    }
  }

  .btn-share {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: $zindex-fixed;
    line-height: 0;
    display: block;
    width: 64px;
    height: 64px;
    padding: 0;
    font-size: 1.5rem;
    border-radius: 32px;
    border: 0;
    color: $white;
    @include gradient-directional(lighten($primary, 7%), darken($primary, 5%), 180deg);
    box-shadow: 0 .5rem 1rem rgba($primary, .3);
  }
  .share-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-fixed - 1;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);

    .share-buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      height: 100%;
      padding-bottom: 6rem;

      button {
        margin: 0 2.5rem .5rem 0;
      }
    }
  }

}

.map-modal {
  width: 100%;
  height: 100%;

  .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: none;
    margin: 0;

    .modal-content {
      width: 100%;
      height: 100%;
      border-color: $primary;
      border-width: 3px;
      border-top: 0;

      .modal-header {
        background-color: $primary;
        border: 0;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;

        .close {
          color: $white;
          opacity: 1;
          text-shadow: none;
          line-height: .8;
        }
      }

      .modal-body {
        padding: 0;
        position: relative;
        background-color: $gray-200;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        height: 100%;

        &:before {
          content: 'Загрузка карты...';
          position: absolute;
          display: block;
          color: $gray-400;
          font-size: 2rem;
          font-weight: bold;
          z-index: 0;
        }
        .ya-map {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
